import mqtt from "mqtt"
const {
    VUE_APP_FCMQTT_URL
} = process.env
export default {

    uint8array2json(uint8array) {
        if (!uint8array || !uint8array.length) {
            return uint8array
        }
        let { decoder } = this.mqtt
        if (!decoder) {
            decoder = new TextDecoder("utf-8")
        }
        let content = decoder.decode(uint8array)
        return content ? JSON.parse(content) : content
    },

    json2uint8array(data) {
        if (!data) {
            return data
        }
        let { encoder } = this.mqtt
        if (!encoder) {
            encoder = new TextEncoder();
        }
        let uint8array = encoder.encode(JSON.stringify(data))
        return uint8array
    },

    init() {
        let { user_info } = this
        if(this.mqtt.client){
            this.mqtt.client.end()
            this.mqtt.client=null
        }
        // const crypto = window.crypto || window.webkitCrypto || window.mozCrypto || window.oCrypto || window.msCrypto;
        // let uuid = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
        let url = VUE_APP_FCMQTT_URL
        // user_info.id
        this.mqtt.client = mqtt.connect(url, {
            // protocol: "mqtt",
            clientId: user_info.id
        })
 
        this.mqtt.client.on("connect", () => {
            console.log("mqtt is connected");
        })
        this.mqtt.client.on("reconnect", function (reconnect) {
            console.log(reconnect, 'reconnect');
        })
        this.mqtt.client.on("message", (topic, payload, packet) => {
            let data = this.mqtt_uint8array2json(payload)
            let deviceHardId = packet.topic.split("/")[2]
            if (data.type === 258) {
                // 更新无人机位置信息
                this.uav_update_location({ deviceHardId }, data.data)
                // 更新控制面板数据
                this.uav_update_control({ deviceHardId }, data.data)
            }
            if (data.type === 265) {
            }
            if (data.type === 268) {
                this.uav_update_channelData({ deviceHardId }, data.data)
            }
            if (data.type === 257) {
                // 无人机电池数据
                this.uav_update_control_battery({ deviceHardId }, data.data)
            }
            if (data.type === 514) {
                // 挂载数据
                this.uav_update_mount({ deviceHardId }, data.data)
            }
            if (data.type === 259) {
                this.uav_update_mount({ deviceHardId }, data.data)
                // 挂载数据
            }
            // 无人机故障信息
            if (data.type === 269) {
                this.$message.info(data.data)
            }
            if (data.type == 270) {
                this.uav.wrjType = data.data
                this.uav.msg_list.unshift({
                    type: data.data.code,
                    text: data.data.text
                })
                if (data.data.code == 10043) {
                    this.$message.success(data.data.text)
                } else if (data.data.code == 10053) {
                    this.$message.success(data.data.text)
                }
            }
            // 无人机提示信息
            if (data.type === 260) {
                this.uav.msg_list.unshift({
                    type: '警告',
                    text: data.data.msg
                })
                this.uav_update_control_info({ deviceHardId }, data.data)
            }
            if (data.type === 500 && data.msgnum === 4132) {
                // 设备上线
                this.uav_update_device_report_status(1, deviceHardId)
            }
            if (data.type === 500 && data.msgnum === 4121) {
                // 设备离线
                this.uav_update_device_report_status(0, deviceHardId)
            }
            if (data.type === 500 && data.msgnum === 4121) {
                // 设备离线
                this.uav_update_device_report_status(0, deviceHardId)
            }
            if (data.type === 264) {
                // 航线上传
                this.airway_upload_message(data.data, packet.topic)
            }

        })

        /**
         * 订阅无人机上线下线消息
         */
        this.mqtt_subscribe(`on_and_off_broker`, (e) => {
            console.log("subscribe on_and_off_broker");
        });
    },

    /**
     * 订阅
     * @param {*} topic 
     * @param {*} callback 
     */
    subscribe(topic, callback) {
        if (!topic) {
            return topic
        }
        let { client } = this.mqtt
        if (client) {
            console.log('订阅数据a', topic);
            client.subscribe(topic, callback)
        }
    },

    /**
     * 取消订阅
     * @param {*} topic 
     * @returns 
     */
    unsubscribe(topic) {
        if (!topic) {
            return topic
        }
        let { client } = this.mqtt
        if (client) {
            client.unsubscribe(topic)
        }
    },

    /**
     * 发布
     * QoS 0：消息最多传递一次，如果当时客户端不可用，则会丢失该消息。
     * QoS 1：消息传递至少 1 次。
     * QoS 2：消息仅传送一次。
     * @param {*} publication 
     * @param {*} callback 
     * @param {*} options 
     * @param {*} type 
     */
    publish(topic, message, callback, options = { qos: 0 }, ) {
        let { client } = this.mqtt
        console.log(message,'发送数据')
        // this.mqtt_json2uint8array(message)
            client.publish(topic,new TextEncoder().encode(JSON.stringify(message)),  options, callback)
    }


}