<template >
  <div class="pitchyawBox">
    <div class="pa" style="left: 40px; top: 14px">
      <div class="infoBox cf">
        <div class="dib infoBox_title">{{ props_obj.height ? props_obj.height.toFixed(2) : 0 }}</div>
        <div class="cf info" style="transform: translateX(-9px);">飞行高度（m）</div>
      </div>
      <div class="line mt9 mb7"></div>
      <div class="infoBox cf">
        <div class="dib infoBox_title">
          {{
          props_obj && props_obj.distanceToHome
          ? props_obj.distanceToHome.toFixed(2)
          : 0
          }}
        </div>
        <div class="cf info">起点距离</div>
      </div>
    </div>
    <div class="pa" style="right: 71px; top: 14px">
      <div class="infoBox cf">
        <div class="dib infoBox_title">{{ props_obj.groundSpeed ? props_obj.groundSpeed.toFixed(2) : 0}}</div>
        <div class="cf info">飞行速度(m/s)</div>
      </div>
      <div class="line2 mt9 mb7"></div>
      <div class="infoBox cf">
        <div class="dib infoBox_title">
          {{
          props_obj.flightTime ? (props_obj.flightTime / 60).toFixed(2) : 0
          }}
        </div>
        <div class="cf info">飞行时间(min)</div>
      </div>
    </div>
    <div class="pa dib w426" style="top:-35px">
      <img class="wih100 h2" src="~@/assets/images/observe/fckernel/line.png" alt />
    </div>
    <div class="pa dib w426" style="bottom:-27px">
      <img class="wih100 h2" src="~@/assets/images/observe/fckernel/line.png" alt />
    </div>

    <div class="pitchyaw h123"></div>
    <ball :uav-data="uavData" class="dib ball pa"></ball>
  </div>
</template>
<script>
import ball from "../ball/index.vue";
export default {
  props: {
    uavData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  components: { ball },
  computed: {
    props_obj() {
      let { uavData } = this;
      if(uavData.uavInfo){
        return uavData.uavInfo
      }else{
        return false
      }

    }
  }
};
</script>
<style lang="scss" scoped>
.pitchyawBox {
  position: relative;
  .pitchyaw {
    background-image: linear-gradient(
      90deg,
      rgba(0, 138, 212, 0) 0%,
      #008ad4 49%,
      rgba(0, 138, 212, 0) 100%
    );
  }
  .ball {
    top: -17px;

    margin-left: calc(50% - 100px);
  }
  .line{
    width: 91px;
    height: 0.1px;
    opacity: 0.6;
    border: 1px solid;
    border-image: linear-gradient(63deg,rgba(135,217,255,0),rgba(135,217,255,1)) 1 1;
  }
  .line2{
    width: 91px;
    height: 0.1px;
    opacity: 0.6;
    border: 1px solid;
    border-image: linear-gradient(63deg,rgba(135,217,255,1),rgba(135,217,255,0)) 1 1;
  }
  .infoBox {
    width: 100px;
    text-align: center;
    .infoBox_title {
      font-family: UniDreamLED;
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 1px;
      font-weight: 400;
    }
    .info {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #78bcea;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
</style>