import mount from "@/components/observe/mount"
import mqtt from "./mqtt"
import uav from "./uav"
import wsLine from "./wsLine"


export default () => ({
    mount,
    mqtt: mqtt(),
    uav: uav(),
    wsLine
})