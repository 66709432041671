<template>
  <div style="width: 100%;height: 100%" class="live pr" ref="live" :class="className">
    <div class="cf" v-if="infoflag">
      <span class="mb10 dib">{{deviceName}}</span>
    </div>
    <div :class="infoflag ?'livenvrfull':''" class="wih100 ht100">
      <LivePlayer
        :dblclick-fullscreen="false"
        class="livenvr-player"
        ref="liveqing-player"
        :controls="controls"
        :videoUrl="videoUrl"
      />
    </div>
  </div>
</template>

<script>
import LivePlayer from "@liveqing/liveplayer";
import { newDate } from "@/utils/formatDate";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    LivePlayerInfor: {
      type: Boolean,
      default: () => true
    },
    infoflag: Boolean,
    controls: {
      type: Boolean,
      default: () => true
    },
    className: String,
    deviceName: String
  },
  components: { LivePlayer },
  data() {
    return {
      videoUrl: "",
      isFullscreenBool: false, //是否全屏
      flag: false,
      ws: null,
      wsData: {},
      timeStr: "",
      timer: null,
      orgflag: false,
      org: {}
    };
  },
  mounted() {
    // this.getFontSize()
    this.timer = setInterval(() => {
      this.timeStr = newDate();
    }, 1000);
    this.initws();
    window.addEventListener("resize", this.fullScreenChangeFn);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    this.ws && this.ws.close();
    this.ws = null;
    window.removeEventListener("resize", this.fullScreenChangeFn);
  },
  methods: {
    initws() {
      this.ws = new WebSocket(process.env.VUE_APP_WS_URL_ALARM);
      let { appid, username } = JSON.parse(
        localStorage.getItem("user_info")
      ).data;
      let token = JSON.parse(localStorage.getItem("user_info")).data[
        "mmc-identity"
      ];
      this.ws.onopen = () => {
        this.ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid
          })
        );
      };

      this.ws.onmessage = e => {
        let metadata = JSON.parse(e.data);
        if (metadata.msgnum == 4700 && metadata.type == 500) {
          if (this.data.deviceHardId == metadata.data.deviceHardId) {
            this.wsData = metadata.data;
            if (
              this.wsData.anotherName &&
              this.wsData.anotherName.indexOf("-") > -1
            ) {
              let arr = this.wsData.anotherName.split("-");
              this.wsData.ername = arr[0];
              this.wsData.sanname = arr[1];
            } else {
              this.wsData.ername = this.wsData.anotherName;
            }
            if (this.wsData.anotherName) {
              this.orgflag = true;
            }
          }
        }
      };
    },
    fullScreenChangeFn(e) {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.isFullscreenBool = false;
        this.flag = false;
      } else {
        this.isFullscreenBool = true;
      }
    },
    closeFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    },
    init() {
      let dom = document.querySelector("." + this.className);
      // console.log(dom,'domdom');
      //  开启全屏
      if (!this.flag) {
        dom.requestFullscreen();
        this.flag = true;
      } else {
        document.exitFullscreen();
        this.flag = false;
      }
    },
    getFontSize() {
      // 获取需要改变字体大小的元素
      let timeStr = this.$refs.timeStr;
      let live = this.$refs.live;
      let wsDataleft = null;
      if (this.data.device_type != 1) {
        wsDataleft = this.$refs.wsDataleft;
      }
      let wsDataright = this.$refs.wsDataright;

      // 根据窗口宽度设置字体大小
      var fontSize = Math.floor(live.clientWidth / 40); // 根据需要进行调整

      // 应用新的字体大小
      timeStr.style.fontSize = fontSize + "px";
      if (wsDataleft) {
        wsDataleft.style.fontSize = fontSize + "px";
        wsDataleft.style["-webkit-transform"] = "scale(0.8)";
      }
      wsDataright.style.fontSize = fontSize + "px";
      timeStr.style["-webkit-transform"] = "scale(0.8)";
      wsDataright.style["-webkit-transform"] = "scale(0.8)";

      // 监听窗口大小变化事件
      window.addEventListener("resize", function() {
        // 根据窗口宽度设置字体大小
        var fontSize = Math.floor(live.clientWidth / 40); // 根据需要进行调整

        // 应用新的字体大小
        if (wsDataleft) {
          wsDataleft.style.fontSize = fontSize + "px";
          wsDataleft.style["-webkit-transform"] = "scale(0.8)";
        }
        timeStr.style.fontSize = fontSize + "px";
        wsDataright.style.fontSize = fontSize + "px";
        timeStr.style["-webkit-transform"] = "scale(0.8)";
        wsDataright.style["-webkit-transform"] = "scale(0.8)";
      });
    }
  },
  watch: {
    data: {
      handler(value) {
        if (value && value.vUrl) {
          this.videoUrl = value.vUrl;
          this.$nextTick(() => {
            this.$refs["liveqing-player"].play();
          });
        }
      },
      deep: true,
      immediate: true
    },
    "data.deviceName": function(val) {
      this.orgflag = false;
    },
    orgflag: function(val) {
      this.org = this.wsData;
    }
  }
};
</script>

<style lang="scss" scoped>
.livenvr-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;

  /deep/ video {
    object-fit: cover;
  }
  /deep/.vjs-tech {
    object-fit: cover !important;
  }
}

.title {
  font-family: MicrosoftYaHei-Bold;
  font-size: 12px;
  width: 82%;
  color: #69ceff;
  letter-spacing: 1px;
  font-weight: 700;
  z-index: 9;
  padding: 5px 20px;
  background: rgba($color: #000000, $alpha: 0.4);
}
.timeStr {
  position: absolute;
  top: 25px;
  right: 0px;
  font-size: 8px;
  font-family: MicrosoftYaHei;
  z-index: 99;
  color: #ffffff;
}
.wsDataleft {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 0;
  color: #fff;
  font-size: 8px;
}
.wsDataright {
  font-size: 8px;
  position: absolute;
  bottom: 0px;
  right: 0;
  // z-index: 99;
  color: #fff;
  text-align: right;
}
.FullscreenStyle {
  font-size: 24px !important;
  height: 38px;
  line-height: 38px;
  text-align: left;
  width: 100% !important;
}
.timeStrStyle {
  top: 50px;
  font-size: 38px;
}
.leftStyle {
  bottom: 80px;
  font-size: 38px;
  left: 10px;
}
.rightStyle {
  font-size: 38px;
  bottom: 80px;
  right: 10px;
  .name {
    padding: 20px 0;
    font-size: 38px;
  }
}
.vjs-volume-panel,
.vjs-playback-rate,
.live-player-stretch-btn,
.vjs-time-divider,
.vjs-duration,
.vjs-live-control {
  display: none !important;
}
// .video-wrapper
/* 隐藏全屏按钮 */
.video-js .vjs-fullscreen-control {
  display: none !important;
}
::v-deep {
  .livenvrfull {
    .livenvr-player {
      .video-wrapper {
        height: 100% !important;
      }
    }
  }
}
</style>