<template>
  <div class="page-observe-fckernel-uav">
    <Header :uav-data="uav.control.data"></Header>

    <div class="fckernelVideos_leftBox" :class="{ collapse: collapseFlag }">
      <img
        @click="collapseFlagfn"
        v-if="collapseFlag"
        style="transform: rotate(180deg)"
        src="~@/assets/images/observe/fckernel/hs.png"
        class="icon-collapse nsetLeftBox_btn"
      />
      <img
        @click="collapseFlagfn"
        v-else
        src="~@/assets/images/observe/fckernel/collapse.png"
        class="icon-collapse nsetLeftBox_btn"
      />
      <div class="dib wih100 mt10 h54 fckernelVideos_leftBox_title">
        <img
          v-if="videoType"
          class="dib top5 cp title h40 pa left100"
          src="./img/title.png"
          alt
        />
        <img
          v-if="!videoType"
          class="dib top5 cp title h40 pa left100"
          src="./img/titleyc.png"
          alt
        />
        <div
          v-if="videoType"
          class="fckernelVideos_yc cf lh57 cp tc"
          @click="changeList('yc')"
        >
          鹰巢列表
        </div>
        <div
          v-if="!videoType"
          class="fckernelVideos_yc cf lh57 cp tc"
          @click="changeList"
        >
          无人机列表
        </div>
      </div>
      <div class="videoBtn">
        <div
          v-for="(item, index) in videoBtnList"
          :class="uav.itemkey == item.id ? 'videoBtn_active' : ''"
          :key="index"
          @click="splitScreenFn(item)"
        >
          {{ item.title }}
        </div>
      </div>

      <!-- 无人机列表 start -->
      <div v-if="videoType" class="uav-search">
        <el-input
          class="uav-search__input"
          clearable
          placeholder="请输入无人机名称/机构名称"
          v-model="uavSearchContent"
          v-on:keyup.enter.native="onUavSearch"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="color: rgba(123, 181, 213, 1)"
          ></i>
        </el-input>

        <el-button class="uav-search__btn" @click="onUavSearch">搜索</el-button>
      </div>
      <UavList
        v-if="videoType"
        @videoChange="videoChange"
        :containerStyle="{
          width: '463',
          height: '538',
        }"
        :list="uav.list"
        @fn="uav_fn"
        style="box-sizing: border-box; padding-left: 10px"
        @refresh="onUavSearch"
      />
      <!-- 无人机列表 end-->
      <!-- 鹰巢列表 start-->
      <div class="cpt-observe-nest-list" v-if="!videoType">
        <div class="ctx-box pl5">
          <div class="list-box pr14">
            <template v-if="list && list.length">
              <Item
                @fn="uav_fn"
                v-for="item in list"
                :key="item.id"
                :data="item"
              />
            </template>
          </div>
        </div>
      </div>

      <!-- 鹰巢列表 end-->
      <!-- 姿态球 -->
      <pitchyaw
        :uav-data="online"
        :class="isFullscreenBool ? '_full_pitchyaw' : 'pitchyaw'"
        class="pitchyaw w429 ml16"
      ></pitchyaw>
    </div>
    <div
      v-if="uav.videos.length > 0"
      v-loading="loading"
      element-loading-text="加载中.."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.9)"
      :class="{
        videoBox_four: uav.itemkey == 4,
        videoBox_one: uav.itemkey == 1,
        videoBox_nine: uav.itemkey == 9,
        videoBox_sixteen: uav.itemkey == 16,
        collapseplay: collapseFlag,
      }"
    >
      <div
        class="player"
        v-for="(video, index) in uav.videos"
        :key="index"
        @click="change_active_item(index)"
      >
        <FloatPlayer
          @exitVideo="exitVideo"
          class="wih100 ht100"
          :device="uav.control.device"
          :class="{
            active_item_style: index == uav.active_item,
            default_item_style: index != uav.active_item,
          }"
          :uav-data="uav.control.data"
          :_index="index"
          :data="video"
        />
      </div>
    </div>

    <!-- 接管记录 -->
    <TakeOver
      @exit="uav.TakeOverFlag = false"
      v-if="uav.TakeOverFlag"
    ></TakeOver>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UavList from "./components/list";
import FloatPlayer from "./components/float-playerfk";
import data from "./data";
import methods from "./methods";
import Header from "./components/header/index";
import TakeOver from "./components/takeOver";
import pitchyaw from "./components/pitchyaw";
import API from "@/api";
import Item from "./item";

import fk from "./fk.json";
export default {
  components: {
    UavList,
    FloatPlayer,
    Header,
    TakeOver,
    Item,
    pitchyaw,
  },
  inject: ["g_cesium_layer"],
  provide() {
    return {
      uav_This: this,
      findList: (search) => {
        if (search) {
          this.uav_list(search);
        } else {
          this.uav_list(search);
          // this.uav_mountList(data.name);
        }
      },
    };
  },
  data() {
    return {
      ...data(),
      uavDevice: null,
      videoType: true, //true 代表无人机 false 代表鹰巢
      loading: true,
      list: [], //鹰巢列表
      collapseFlag: false,
      videoBtnList: [
        {
          title: "单屏",
          id: 1,
        },
        {
          title: "四分屏",
          id: 4,
        },
        {
          title: "九分屏",
          id: 9,
        },
        {
          title: "十六分屏",
          id: 16,
        },
      ],
      videoFlag: true,
      uavSearchContent: "", //无人机搜索内容
      videoList: "", //数据列表
      fk: fk,
      ws: null,
      isFullscreenBool: false, //是否是全屏
      deviceHardId: null,
      online: {
        uavInfo: {},
        position: [],
        orientation: [],
      },
      positions: [],
    };
  },
  provide() {
    return {
      fn: (...args) => this.uav_fn(...args),
    };
  },
  computed: {
    ...mapGetters(["user_info"]),

    uav_mounts() {
      let { mounts } = this.uav.control;
      return mounts
        .map((item) => {
          let find_item = this.mount.list.find(
            (m) => m.name === item.gimbalName
          );
          return find_item || undefined;
        })
        .filter((item) => item);
    },
  },
  async mounted() {
    this.uav_list(null, true);
    await this.get_uav_videos();
    this.wsLine_initws();
    document.title = "视频墙";
    // 鹰巢列表
    this.nest_list();
    // 地图
    this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
      flag: true,
      type: "fkVideo",
    });

    window.addEventListener("resize", this.fullScreenChangeFn);
    // this.mqtt_init();
  },
  watch: {
    deviceHardId: {
      handler(val) {
        if (val) {
          this.wsLine_initModel(val);
        }
      },
    },
  },
  beforeDestroy() {
    // 移除航线
    this.wsLine_remove_model();
    this.uav.control.device = null;
    this.uav.control.device = null;
    this.uav.control.data = null;
    this.uav.control.battery = null;
    this.uav.control.mount = null;
    this.uav.control.mounts = [];
    this.uav.control.mounts = [];
    if (this.ws) {
      this.ws.close();
    }
    this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
      flag: false,
      type: "fkVideo",
    });
    this.uav.videos = [];
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
    window.removeEventListener("resize", this.fullScreenChangeFn);
  },
  methods: {
    ...methods,
    collapseFlagfn() {
      this.collapseFlag = !this.collapseFlag;
      if (this.collapseFlag) {
        this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
          flag: true,
          type: "enable",
        });
      } else {
        this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
          flag: false,
          type: "enable",
        });
      }
    },
    changeList(type) {
      if (type == "yc") {
        this.videoType = false;
      } else {
        this.videoType = true;
      }
      this.uav.videos = [
        { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
        { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
        { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
        { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
      ];
      this.get_uav_videos();
    },
    async nest_list() {
      try {
        let data = await API.NEST.getNestList();
        function each(tree, data) {
          return tree.map((item) => ({
            ...item,
            offLineCount: data.offLineCount,
            onLineCount: data.onLineCount,
            collapse: false,
            child_collapse: false,
            reportStatus: 1,
            child: item.child && item.child.length && each(item.child),
          }));
        }
        if (data.tree && data.tree.length) {
          data = each(data.tree, data);
        }
        this.list = data || [];
      } catch (error) {}
    },
    exitVideo(index) {
      this.uav.videos[index] = {
        id: "",
        deviceName: "",
        deviceHardId: "",
        osd: 0,
        videos: {},
      };
      this.$forceUpdate();
    },
    change_active_item(index) {
      if (this.uav.active_item != index) {
        // 移除航线
        this.wsLine_remove_model(this.deviceHardId);
      }
      this.uav.active_item = index;
      setTimeout(() => {
        this.deviceHardId = this.uav.videos[index].deviceHardId || null;
      }, 1500);
    },
    splitScreenFn(item) {
      if (item.id != 1) {
        this.loading = true;
      }
      this.uav.itemkey = item.id;
      this.uav.active_item = 0;
      if (item.id == 1) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
        ];
      } else if (item.id == 4) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
        ];
      } else if (item.id == 9) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
        ];
      } else {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
          { id: "", deviceName: "", deviceHardId: "", osd: 0, videos: {} },
        ];
      }

      this.get_uav_videos();
      this.$forceUpdate();
    },
    //监听屏幕是否全屏
    fullScreenChangeFn(e) {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.isFullscreenBool = false;
      } else {
        this.isFullscreenBool = true;
      }
    },
    async get_uav_videos() {
      let res = await API.HOME.getDeviceStreamAddressList({
        stationType: this.videoType ? 3 : 2,
      });
      let array = res;
      let videoList = [];
      this.uav.videos = [];
      let videos = [];
      for (let i = 0; i < res.length; i++) {
        videoList.push(array[i].deviceHardId);
        if (videos.length < this.uav.itemkey) {
          videos.push({
            id: i,
            deviceHardId: array[i].deviceHardId,
            deviceName: array[i].deviceName,
            osd: array[i].osd || 0,
            videos: array[i],
          });
        }
      }
      for (let i = 0; i < this.uav.itemkey + 1; i++) {
        if (videos.length < this.uav.itemkey) {
          videos.push({
            id: "",
            deviceName: "",
            deviceHardId: "",
            osd: 0,
            videos: {},
          });
        }
      }

      this.uav.videoList = videoList;
      this.uav.videos = videos;
      setTimeout(() => {
        this.loading = false;
        this.deviceHardId = this.uav.videos[this.uav.active_item].deviceHardId;
        if (this.deviceHardId) {
          // this.uav.control.data = this.wsLine.online[this.deviceHardId].uavInfo;
        }
      }, 2000);
      this.$forceUpdate();
    },
    cesium_layer_change_mode(fn, modes) {
      // fn(modes.GLOBE);
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          120.218241,
          33.165767,
          21812.321476599747
        ),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14,
        },
        time: 5,
      });
    },
    videoChange() {
      this.videoFlag = !this.videoFlag;
    },
    /**
     * 无人机搜索
     */
    onUavSearch() {
      this.uav_list({
        search: this.uavSearchContent,
      });
    },
  },

  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
.page-observe-fckernel-uav {
  height: 100%;
  // overflow: hidden;
  // background: #183b5e;
  background: #000000;
  .cpt-observe-nest-list {
    height: 39vh;
    overflow-y: auto;
    padding: 0 20px;
  }
  .fckernelVideos_leftBox {
    background-image: linear-gradient(180deg, #1e4d8a 0%, #132148 100%);
    border: 1px solid #70daf9;
    width: 467px;
    position: fixed;
    height: calc(100% - 57px);
    top: 70px;
    z-index: 10;
    .nsetLeftBox_btn {
      position: absolute;
      cursor: pointer;
      right: -22px;
      top: 50%;
      margin-top: -90px;
      z-index: 99;
    }
    .fckernelVideos_leftBox_title {
      background-image: url("./img/fckernelVideos_leftBox_title.png");
      background-repeat: no-repeat;
    }
    .fckernelVideos_yc {
      position: absolute;
      height: 45px;
      width: 150px;
      right: 40px;
      background-size: 100% 100%;
      top: 5px;
      background-image: url("./img/ycbj.png");
      background-repeat: no-repeat;
    }
    .videoBtn {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      background-color: #07173b;
      width: 80%;
      margin-left: 10%;
      border-radius: 20px;
      border: 1px solid #2267cf;
      div {
        cursor: pointer;
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: #fff;

        // border: 1px solid #999;
        border-radius: 20px;
        // margin-right: -1px;
      }
      .videoBtn_active {
        border-radius: 20px;
        color: #fff;
        background-image: url("./img/videoBtn_active.png");
        background-size: 100% 100%;
      }
    }

    .pitchyaw {
      position: fixed;
      top: 59%;
    }
    ._full_pitchyaw {
      position: fixed;
      top: 64%;
    }
  }
  .collapse {
    transform: translateX(-100%);
    transition: 0.3s;
  }
  .videoBox_one {
    width: 100vw;
    margin-left: 468px;
    position: fixed;
    top: 65px;
    z-index: 1;
    height: 80vh;
    display: flex;
    flex-wrap: wrap;

    .player {
      width: calc(100% - 470px);
      height: calc(100vh - 60px);
      position: relative;
      &::v-deep {
        .qingliu {
          canvas {
            height: 98vh;
          }
        }
        .play {
          position: absolute;
          bottom: 0px;
        }
        .video-wrapper {
          inset: 0px;
          height: calc(88vh);
          padding-bottom: 0 !important;
        }
      }
    }
  }
  .active_item_style {
    border: 1px solid red;
  }
  .default_item_style {
    border: 1px solid #eee;
  }
  .videoBox_four {
    width: calc(100% - 465px);
    margin-left: 465px;
    position: fixed;
    top: 65px;
    padding-bottom: 10px;
    z-index: 1;
    height: calc(100vh - 80px);
    display: flex;
    flex-wrap: wrap;
    .player {
      width: calc(98% / 2);
      margin: 5px;
      height: 46vh;

      position: relative;

      &::v-deep {
        .qingliu {
          left: 0;
          canvas {
            height: calc(91.5vh / 2);
            top: 0px;
            position: absolute;
            left: 0;
          }
        }
        .play {
          bottom: 0px;
        }
        .video-wrapper {
          inset: 0px;
          height: calc(92vh / 2);
          padding-bottom: 0 !important;
        }
      }
    }
  }
  .videoBox_nine {
    width: calc(100vw - 465px);
    margin-left: 465px;
    position: fixed;
    display: flex;
    top: 76px;
    left: 10px;
    z-index: 1;
    height: calc(100vh - 80px);
    flex-wrap: wrap;
    .player {
      width: calc(97% / 3);
      margin: 5px;
      // border: 1px solid red;
      position: relative;
      &::v-deep {
        .qingliu {
          canvas {
            height: calc(88.5vh / 3);
          }
        }
        .play {
          bottom: 0px;
        }
        .video-wrapper {
          height: calc(88.5vh / 3);
          padding-bottom: 0 !important;
        }
      }
    }
  }
  .videoBox_sixteen {
    width: calc(100vw - 465px);
    margin-left: 465px;
    position: fixed;
    display: flex;
    top: 76px;
    left: 10px;
    z-index: 1;
    height: calc(100vh - 80px);
    flex-wrap: wrap;
    .player {
      width: calc(95% / 4);
      margin: 5px;
      // border: 1px solid red;
      position: relative;
      &::v-deep {
        .qingliu {
          canvas {
            height: calc(87vh / 4);
          }
        }
        .play {
          bottom: 0px;
        }
      }
    }
  }
  .collapseplay {
    width: 100%;
    margin: 0;
  }
  .uav-search {
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;

    .uav-search__input ::v-deep {
      width: 317px;
      height: 32px;
      box-shadow: 0px 2px 4px 0px rgba(28, 94, 124, 0.5),
        inset 0px 0px 3px 0px #00b6ff;
      border-radius: 4px;

      .el-input__icon {
        line-height: 32px;
      }

      input {
        height: 32px;
        background: rgba(0, 0, 0, 0);
        color: #43deff;
        border: 1px solid #00b6ff;

        &::placeholder {
          font-size: 12px;
          color: #7bb5d5;
        }
      }
    }

    .uav-search__btn {
      padding: 7px 27px 9px 28px;
      background: rgba(23, 70, 216, 0.2);
      box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
        inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
        inset 0px 0px 4px 0px #00a7ff;
      border-radius: 4px;
      border: 1px solid;
      font-size: 12px;
      color: #00ffff;
    }
  }
}
</style>
