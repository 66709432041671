export default {
  initws() {
    this.ws = new WebSocket(process.env.VUE_APP_WS_URL_ALARM);
    let {
      appid,
      username
    } = JSON.parse(
      localStorage.getItem("user_info")
    ).data;
    let token = JSON.parse(localStorage.getItem("user_info")).data[
      "mmc-identity"
    ];
    this.ws.onopen = () => {
      this.ws.send(
        JSON.stringify({
          type: 100,
          systemCode: "mmc",
          state: 1,
          username,
          token,
          appId: appid,
        })
      );
    };
    this.ws.onmessage = (e) => {
      let metadata = JSON.parse(e.data);
      if (metadata.msgnum == 4700 && metadata.type == 500) {
        if (metadata.data.deviceHardId == this.deviceHardId) {
          this.wsLine_update_locations(metadata.data)
        }
      }
    };
  },
  update_locations(uav_data) {
    let online = this.online
    if (online) {
      let data = this.wsLine_trans_mqtt_location_data({}, uav_data)
      online.orientation = data.orientation
      online.position = data.position
      online.uavInfo = data.uavInfo
  
      if (data.positions.length > 0) {
        this.positions.push(data.position)
      }
    }
  },
  trans_mqtt_location_data(device, uav_data) {
    // 获取航线
    let uavInfo = uav_data
    let position = Cesium.Cartesian3.fromDegrees(
      uavInfo.longitude * 1,
      uavInfo.latitude * 1,
      (uavInfo.altitude * 1)
    );

    let positions = [];
    let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
      pitch = Cesium.Math.toRadians(uavInfo.pitch),
      roll = Cesium.Math.toRadians(uavInfo.roll),
      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
      orientation = Cesium.Transforms.headingPitchRollQuaternion(
        position,
        hpr
      );
    let model = viewer.entities.getById(`fckernelVideos_model_`)
    if (model) {
      const curPos = model.position.getValue(
        Cesium.JulianDate.now()
      );
      if (curPos) {
        const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z);
        const afterCart = new Cesium.Cartesian3(position.x, position.y, position.z);
        if (curCart.equals(afterCart)) {} else {
          // 航线数据
          positions.push(position);
        }
      } else {}
    }

    return {
      position,
      positions,
      orientation,
      uavInfo
    };
  },
  initModel(deviceHardId) {
    setTimeout(() => {
      if (deviceHardId) {
        let viewer = window.viewer
        let model = viewer.entities.getById(`fckernelVideos_model_`)
        if (!model && viewer) {
          this.positions =[ this.online.position]
          this.entitiesId = `fckernelVideos_model_`
            if (this.positions) {
              model = viewer.entities.add({
                id: this.entitiesId,
                orientation: new Cesium.CallbackProperty(() => this.online.orientation, false),
                position: new Cesium.CallbackProperty(() => this.online.position, false),
                model: {
                  uri: "cesium/model/mmcModel2.glb",
                  minimumPixelSize: 120,
                  maximumScale: 10000,
                  scale: 1,
                },
                polyline: {
                  positions: new Cesium.CallbackProperty(() => {
                    return this.positions;
                  }, false),
                  width: 5,
                  show: true,
                  material: Cesium.Color.fromCssColorString("#ff0000"),
                },
              });
          //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
          var ellipsoid = viewer.scene.globe.ellipsoid;
          var cartesian3 = model.position.getValue(Cesium.JulianDate.now());
          var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3);
          var lat = Cesium.Math.toDegrees(cartogtraphic.latitude);
          var lng = Cesium.Math.toDegrees(cartogtraphic.longitude);
          
          viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000),
          maximumHeight: 100,
          complete: () => {
            // 锁定
            viewer.trackedEntity = model;
          },
        });
        model.viewFrom = new Cesium.Cartesian3(0, 0, 800);
            }
        }
      }
    },1000);
  },
  remove_model(deviceHardId) {
    if (this.entitiesId) {
      window.viewer.entities.removeById(this.entitiesId)
    } else {
      window.viewer.entities.removeById(`fckernelVideos_model_`);
    }
  }
}