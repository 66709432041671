<template>
  <div class="nest_header_box jcsb">
    <div class="nest_header_box_left">
      <div class="date-box">
        <div class="yyyy">{{ date }}</div>
        <div></div>
        <div class="week-box">
          <div class="week">{{ week }}</div>
          <div class="hhmm">{{ time }}</div>
        </div>
      </div>
      <img class="w35 h35 ml21" :src="weatherIcon" alt />
      <div>
        <div style="color: #00ffff" class="ml36">
          <div>{{ weather_data.windDirection }}风</div>
          {{ weather_data.minTemp || 0 }}°~{{ weather_data.maxTemp || 0 }}°
        </div>
      </div>
      <div></div>
      <el-tooltip content="烟感" placement="bottom" class="nones" disabled>
        <div class="symbol-icon-box ml30">
          <SymbolIcon :icon="fire[fire_state]" />
        </div>
      </el-tooltip>

      <el-tooltip content="水浸" placement="bottom" class="nones" disabled>
        <div class="symbol-icon-box ml20">
          <SymbolIcon :icon="water[water_state]" />
        </div>
      </el-tooltip>
      <div
        class="noFlight ml28"
        v-if="weather_data && weather_data.flyStatus == 0"
      >
        不宜起飞
      </div>
      <div class="isFlight ml28" v-else>适合起飞</div>
      <div
        class="changeBtn lh50 cp tc"
        @click="$router.push('/fckernel?page=1')"
      >
        无人机应用
      </div>
    </div>

    <div class="w300 cp ht100 pa gohome" @click="$router.push('/home')" />
    <div class="icon-box nest_header_box_right">
      <div
        class="changeBtn lh50 cp tc lh50"
        @click="$router.push('/fckernel?page=2')"
      >
        鹰巢应用
      </div>
    </div>
  </div>
</template>

<script>
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
import API from "@/api";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  components: { SymbolIcon },
  props: {
    uavBattery: {
      type: Object,
    },
    nestData: {
      type: Object,
    },

    uavData: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["g_weather"],
  data() {
    return {
      mode: "离线状态",
      typeNet: 0,
      weatherIcon: null,
      now: null,
      curr_val: 0,
      parachuteStateList: [
        {
          icon: "jianglasanzhengchang",
          title: "降落伞进入警戒",
        },
        {
          icon: "jinrujingjie",
          title: "降落伞进入警戒",
        },
        {
          icon: "tuichujingjie",
          title: "降落伞退出警戒",
        },
        {
          icon: "kaisan",
          title: "降落伞开伞",
        },
      ],

      parachuteList: [
        {
          icon: "jianglasanzhengchang",
          title: "正常",
        },
        {
          icon: "jianglasanweiqiyong",
          title: "未启用",
        },
        {
          icon: "weizaixian",
          title: "控制器不在线",
        },
        {
          icon: "jianglasansanbaoweilianjie",
          title: "伞包未连接",
        },
        {
          icon: "jianglasanguzhang",
          title: "降落伞故障",
        },
      ],
      remote: {
        0: "yaokongqi-duankailianjie",
        1: "yaokongqiliang",
      },
      week_map: {
        0: "天",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      },
      ws: null,
      week_map: {
        0: "天",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      },
      fire: {
        0: "yanganan",
        1: "yanganhong",
        2: "yanganliang",
      },
      water: {
        0: "shuijinan",
        1: "shuijinhong",
        2: "sfhuijinliang",
      },
      power: {
        1: "dianliangbaifenzhiershi",
        2: "dianliangbaifenzhisishi",
        3: "dianliangbaifenzhiliushi",
        4: "dianliangbaifenzhibashi",
        5: "chongdianmoshi5",
      },
      weather: {},
      weather_data: {
        weather: "晴",
        icon: require(`@/assets/images/weather/icon/day/01.png`),
        temperature: 0,
        humidity: 0,
        windPower: "",
        windSpeed: "",
        minTemp: "",
        maxTemp: "",
        windDirection: "东北",
      },
      // <el-option label="1.4G" :value="2"> </el-option>
      //     <!-- 公网  -->
      //     <el-option label="4G/5G" :value="3"> </el-option>
      //     <el-option label="自动" :value="0"> </el-option>
      ModeStatusList: [
        { id: 2, data: "1.4G", label: "专网" },
        { id: 3, data: "4G/5G", label: "公网" },
        { id: 0, data: "自动", label: "自动" },
      ],
      zwArr: [
        {
          icon: "xinhaoshaixuan",
          info: "暂无",
        },
        {
          icon: "a-14-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-14-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-14-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-14-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-14-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-14-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      gwArr: [
        {
          icon: "xinhaoshaixuan",
          info: "暂无",
        },
        {
          icon: "a-5g-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-5g-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-5g-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-5g-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-5g-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-5g-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      modeList: [
        { id: 1, data: "MANUAL", label: "手动模式", disabled: false },
        { id: 2, data: "ALTITUDE", label: "定高模式", disabled: false },
        { id: 3, data: "POSITION", label: "GPS模式", disabled: false },
        { id: 4, data: "AUTO_MISSION", label: "航线模式", disabled: false },
        { id: 5, data: "ACRO", label: "特技模式", disabled: true },
        { id: 6, data: "OFF_BOARD", label: "版外模式", disabled: true },
        { id: 7, data: "STABILIZED", label: "自稳模式", disabled: true },
        { id: 8, data: "HOLD", label: "定点模式", disabled: false },
        { id: 9, data: "LAND", label: "降落模式", disabled: false },
        { id: 10, data: "TAKE_OFF", label: "起飞模式", disabled: true },
        { id: 11, data: "AUTO_RTL", label: "返航模式", disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: "跟随模式",
          disabled: true,
        },
      ],

      signal: {
        1: "GPS1",
        2: "GPS2",
        3: "GPS3",
        4: "GPS4",
        5: "GPS5",
      },
      typeNeticon: "xinhaoshaixuan",
      isSelect: true,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.curr_val = value;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    rcState() {
      let { uavData } = this;
      return uavData ? uavData.rcState : 0;
    },
    parachute() {
      let { uavData } = this;
      return uavData ? uavData.parachute : 3;
    },
    parachuteState() {
      let { uavData } = this;
      return uavData ? uavData.parachuteState : 2;
    },
    gzSignal() {
      let { uavData } = this;
      let gzSignal = {
        gw: 1,
        zw: 1,
      };
      if (uavData && uavData.signal && uavData.signal.sqe45) {
        gzSignal.gw = uavData.signal.sqe45;
      }
      if (uavData && uavData.signal && uavData.signal.sqe14) {
        gzSignal.zw = uavData.signal.sqe14;
      }
      return gzSignal;
    },
    FlightMode() {
      let { uavData, modeList } = this;
      let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
      return flightMode?.label || "离线状态";
    },
    ModeStatus() {
      let { uavData } = this;
      let name = null;
      if (uavData && uavData.link) {
        for (let i = 0; i < uavData.link.length; i++) {
          const k = uavData.link[i];
          if (k.priority == 2 && k.using) {
            return "专网";
          } else if (k.priority == 3 && k.using) {
            return "公网";
          } else if (k.priority == 1 && k.using) {
            return "图传";
          }
        }
      }
      return name || "离线状态";
    },
    priority() {
      let { uavData } = this;
      let name = "离线";
      if (uavData && uavData.priority == 0) {
        name = "自动切换";
      } else if (uavData && uavData.priority == 1) {
        name = "图传";
      } else if (uavData && uavData.priority == 2) {
        name = "专网";
      } else if (uavData && uavData.priority == 3) {
        name = "公网";
      }
      return name;
    },
    fire_state() {
      let { nestData } = this;
      return nestData?.fireWarn ? nestData.fireWarn : 0;
    },

    FIX() {
      let { uavData } = this;
      return (uavData && uavData.isRtkEnable == true) || 0;
    },
    water_state() {
      let { nestData } = this;
      return nestData?.waterWarn ? nestData.waterWarn : 0;
    },
    is_charger() {
      let { nestData } = this;
      return nestData && nestData.chargerPower == 1;
    },
    power_state() {
      let { uavBattery } = this;
      if (uavBattery && uavBattery.chargeRemaining) {
        return parseInt(uavBattery && uavBattery.chargeRemaining / 20 + 1);
      } else {
        return 1;
      }
    },
    remote_state() {
      let { uavData } = this;
      return uavData ? (uavData.isRcConnecting ? 1 : 0) : 0;
    },
    voltage() {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.voltage || 0 : 0;
    },
    power_num() {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.chargeRemaining : 0;
    },
    date() {
      let { now } = this;
      return now ? `${now.format("YYYY/MM/DD")}` : "0000/00/00";
    },
    week() {
      let { now, week_map } = this;
      return now ? `周${week_map[now.day()]}` : "周N/A";
    },
    time() {
      let { now } = this;
      return now ? `${now.format("HH:mm")}` : "00:00";
    },
    satelliteCount() {
      let { uavData } = this;
      return uavData?.gps?.satelliteCount || 0;
    },
    signal_state() {
      let { uavData } = this;
      if (uavData && uavData.satelliteCount) {
        let count = parseInt(uavData.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavData.satelliteCount ? count : 1;
      }
      return 5;
    },
  },

  created() {
    this.now = dayjs();
  },
  mounted() {
    this.getWeather();
    setInterval(() => {
      this.now = dayjs();
    }, 500);
  },
  methods: {
    async getWeather() {
      let organId = { organId: this.user_info["departmentId"] };
      let res = await API.WEATHER.getNewWeather(organId);
      if (res.length > 0) {
        this.weather_data = res[0];
      }
      // console.log(this.weatherList,'this.weatherListthis.weatherList');
      const date = new Date();
      let time = date.getHours();
      this.weatherIcon = require(`@/assets/images/weather/icon/iconfont/W${
        time >= 18 ? this.weather_data.nightIcon : this.weather_data.dayIcon
      }.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.nest_header_box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  background: center url("~@/assets/images/observe/fckernel_videos_header.png");
  background-size: 100%;
  background-size: cover;

  .gohome {
    left: 50%;
    transform: translateX(-50%);
  }

  .ZW {
    width: 25px;
    height: 25px;
    background: url("~@/assets/images/observe/fckernel/nest/ZW.svg") no-repeat;
    background-size: 100% 100%;
  }

  .GW {
    width: 25px;
    height: 25px;
    background: center url("~@/assets/images/observe/fckernel/nest/GW.svg")
      no-repeat;
    background-size: 100% 100%;
  }

  .nest_header_box_right {
    height: 62px;
    min-width: 490px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .changeBtn {
      position: absolute;
      top: 5px;
      right: calc(50% - 370px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/right.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #70f6f9;
    }

    .input_group {
      .symbol-icon-box {
        svg {
          width: 24px;
          height: 24px;
        }
      }

      display: flex;
      align-items: center;

      /deep/.el-select {
        width: 80px;
        color: #fff;
        border: none;
        background: transparent;

        .el-input--suffix {
          display: flex;
        }

        .el-input__inner {
          position: relative;
          right: -8px;
          width: 50px;
          padding: 0;
          border: none;
          color: #fff;
          background: transparent;
        }

        .el-input__suffix {
          position: relative;
        }
      }
    }

    .jgjl {
      background: url("~@/assets/images/observe/fckernel/nest/jgjl.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .changeBtn:hover {
      background: center
        url("~@/assets/images/observe/fckernel/nest/rightD.png");
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isSelect {
      background: center
        url("~@/assets/images/observe/fckernel/nest/btnActiveRight.png");
      background-size: contain;
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }
  }

  .nest_header_box_left {
    margin-top: -5px;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    padding-left: 117px;

    .changeBtn {
      position: absolute;
      top: 5px;
      left: calc(50% - 370px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/leftD.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #70f6f9;
      letter-spacing: 0;
    }

    .changeBtn:hover {
      background: center url("~@/assets/images/observe/fckernel/nest/left.png");
      // background-size: contain;
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isSelect {
      background: center
        url("~@/assets/images/observe/fckernel/nest/btnActive.png");
      background-size: contain;
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #6aff64;
      letter-spacing: 0;
      font-weight: 400;
    }

    .noFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #ffc15b;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  .nav-list {
    width: 590px;
    height: 77px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;

    .nav-item {
      width: 223px;
      height: 77px;
      box-sizing: border-box;
      background: center url("~@/assets/images/observe/fckernel/nav-item.png")
        no-repeat;
      background-size: cover;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover,
      &.selected {
        background: center
          url("~@/assets/images/observe/fckernel/nav-item-selected.png")
          no-repeat;

        .text-box {
          color: #3df1ff;
        }
      }

      .text-box {
        line-height: 77px;
        font-family: Microsoft YaHei Bold;
        font-size: 20px;
        color: #aab8d1;
        font-weight: 700;
        transition: 0.3s;
      }

      &._2 {
        transform: scaleX(-1);

        .text-box {
          transform: scaleX(-1);
        }
      }
    }
  }

  .date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 0;
    height: 60px;
    box-sizing: border-box;

    .yyyy {
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 3px;
    }

    .week-box {
      display: flex;
      justify-content: space-between;

      .week {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }

      .hhmm {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.mode_nameBox {
  width: 116px;
  height: 28px;
  background: url("~@/assets/images/observe/fckernel/nest/select.png") no-repeat;
  background-size: contain;
  margin-right: 5px;
  padding-left: 15px;
  display: flex;
  align-items: center;

  .mode_name {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    color: #3df1ff;
    margin: 0 6px 0 0;
  }
}

.el-dropdown-menu {
  .el-dropdown-menu__item {
    background: rgba(9, 32, 87, 0.7) !important;
  }
}

.noStyle {
  opacity: 0.3;
}

.el-dropdown {
  color: #3df1ff;
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;

  .el-icon-caret-bottom {
    color: #fff !important;
    line-height: 24px;
  }
}

.nones {
  opacity: 0;
}
</style>
