<template>
  <div class="qingliu pr" :class="classNames" id="qinglliu" ref="qingliu">
    <div :id="'qingliu_' + name" class="qingcanvas" ref="qingcanvas">
<div
      class="timeStr"
      ref="timeStr"
      :class="infoflag ? 'timeStrStyle' : ''"
      v-if="device.deviceHardId"
      v-show="isInfoShow && !device.videos.osd"
    >{{ timeStr || "" }}</div>
    <div
      class="wsDataleft"
      ref="wsDataleft"
      :class="infoflag ? 'leftStyle' : ''"
      v-if="device.deviceHardId"
      v-show="isInfoShow && !device.videos.osd"
    >
      <div>经度：{{ wsData.longitude || 0 }}</div>
      <div>纬度：{{ wsData.latitude || 0 }}</div>
      <div>高度：{{ wsData.height ? wsData.height.toFixed(2) : 0 }}</div>
      <div>速度：{{ wsData.groundSpeed ? wsData.groundSpeed.toFixed(2) : 0 }}</div>
      <div>方向：{{ wsData.yaw ? wsData.yaw.toFixed(2) : 0 }}</div>
    </div>
    <div
      class="wsDataright"
      ref="wsDataright"
      :class="infoflag ? 'rightStyle' : ''"
      v-if="device.deviceHardId"
      v-show="isInfoShow && !device.videos.osd"
    >
      <!-- <div v-if="device.customName">{{ org.ername || "" }}</div>
      <div v-else>{{ org.ername || "" }}</div>
      <div v-if="org.sanname">{{ org.sanname || "" }}</div> -->
      <div class="name">{{ deviceName }}</div>
    </div>
    </div>
    <!-- <div class="cf pa top0 wih100" style="z-index:19"  v-show="infoflag" >
      <span class="mb10 w489 fw700 dib f24" style="color:#69ceff">{{deviceName}}</span>
    </div>-->
    
    
    <div
      ref="wtht"
      :id="'wtht' + name"
      :class="infoflag ? 'qingliufull' : ''"
      class="wih100 ht100 pr"
    >
      <div ref="player_area"></div>
    </div>
    
  </div>
</template>

<script>
import { dataURLToBlob, canvasToDataURL } from "@/utils/image-tool";
import { newDate } from "@/utils/formatDate";

export default {
  name: "QingLiuPlayer",
  props: {
    data: Object,
    raw_msg: Number,
    infoflag: Boolean,
    deviceName: String,
    info: {
      type: Boolean,
      default: true
    },
    isInfoShow: {
      type: Boolean,
      default: true
    },
    className: String,
    device: Object
  },
  data() {
    return {
      name: "QingLiuPlayer" + Date.now(),
      ws: null,
      wsData: {},
      timeStr: "",
      timer: null,
      flag: false,
      org: {},
      canvasWidth: 0,
      canvasHeight: 0,
      entry1: "",
      crossNum1: "",
      crossNum2: "",
      entry2: "",
      isStatus:false,
      classNames:"",
    };
  },
  watch: {
    deviceName: function(val) {
      this.flag = false;
    },
    flag: function(val) {
      this.org = this.wsData;
      if (this.device.customName) {
        if (this.device.customName.includes("县")) {
          this.org.ername = this.device.customName.substr(0, 3);
        } else {
          this.org.ername = this.device.customName.substr(0, 2);
        }
      }
    },
    isInfoShow: function(val) {
      if (!this.data.type) {
        this.device.videos = {
          osd: 0
        };
      }
    },
    "$store.state.mapmanage.mapisStatus": function(newVal) {
      console.log(newVal,"newValnewValnewVal");
      if (newVal.type == "wrj") {
        setTimeout(() => {
          window.kbt_player_resize("qingliu_"+this.name);
        }, 1000);
          this.isStatus = newVal.flag;
          if (this.isStatus) {
            this.classNames = this.className + " qingliumap" 
          }else{
            this.classNames = this.className
          }
      }
      if(newVal.type == "yc"){
        window.kbt_player_resize("qingliu_"+this.name);
        this.isStatus = newVal.flag;
        if (this.isStatus && newVal.num == 0) {
            this.classNames = this.className + " ycqingliumap" 
        }else{
          this.classNames = this.className
        }
      }
    },
  },
  created() {},
  mounted() {
    console.log(this.data.vUrl,"this.data.vUrl");
    this.classNames = this.className
    if (this.data.type) {
      this.device.videos = {
        osd: this.data.videos.osd,
      };
    }
    //鹰巢视频大小判断
    console.log(this.$store.state.mapmanage.mapisStatus,"this.$store.state.mapmanage.mapisStatus");
    if(this.$store.state.mapmanage.mapisStatus.flag && this.$store.state.mapmanage.mapisStatus.type == "yc" && this.$store.state.mapmanage.mapisStatus.num == 0){
      this.classNames = this.className + " ycqingliumap" 
    }
    if(this.$store.state.mapmanage.mapisStatus.flag && this.$store.state.mapmanage.mapisStatus.type == "wrj"){
      this.classNames = this.className + " qingliumap" 
    }

    this.getFontSize();
    if (this.device.customName) {
      if (this.device.customName.includes("县")) {
        this.org.ername = this.device.customName.substr(0, 3);
      } else {
        this.org.ername = this.device.customName.substr(0, 2);
      }
    }
    this.timer = setInterval(() => {
      this.timeStr = newDate();
    }, 1000);
    this.initws();
    window.onresize = e => {
      const clientheight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let isFullScreen = screen.height == clientheight;
      if (isFullScreen == true) {
        return;
      } else {
        this.$emit("close");
      }
    };
    window.qingliu = this.init;
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    this.ws && this.ws.close();
    this.ws = null;
    window.kbt_player_destroy("qingliu_"+this.name);
    window.removeEventListener("resize", this.handResize);
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      // 新
      window.kbt_sdk_load(this.data.vUrl, "qingliu_" + this.name);

      // 监听窗口大小变化事件
      window.addEventListener("resize", this.handResize);
      var canvas_qinliu = document.getElementById("qingliu_" + this.name);
      var resizeObserver = new ResizeObserver((e)=>{
        for( let i of e){
          window.kbt_player_resize("qingliu_"+this.name);
        }
      })
      resizeObserver.observe(canvas_qinliu)
      // 旧
      // window.kbt_player_destroy(this.name);
      // setTimeout(() => {}, 1500);
      // window.kbt_sdk_load().then(() => {
      //   let parentObj = this.$refs.player_area;
      //   window.kbt_player_create(
      //     this.name,
      //     this.data.vUrl,
      //     970,
      //     545,
      //     parentObj
      //   );
      // });
    },
    handResize() {
      // 获取 Canvas 元素
      var canvas_qinliu = document.getElementById("qingliu_" + this.name);
      // 获取 Canvas 的初始宽度和高度
      var initialWidth = canvas_qinliu.width;
      var initialHeight = canvas_qinliu.height;
      // 获取当前 Canvas 的宽度和高度
      var currentWidth = canvas_qinliu.clientWidth;
      var currentHeight = canvas_qinliu.clientHeight;
      // 检查 Canvas 的大小是否发生变化
      if (currentWidth !== initialWidth || currentHeight !== initialHeight) {
        // 执行适应新尺寸的操作
        window.kbt_player_resize("qingliu_"+this.name);
      }
    },
    initws() {
      this.ws = new WebSocket(process.env.VUE_APP_WS_URL_ALARM);
      let { appid, username } = JSON.parse(
        localStorage.getItem("user_info")
      ).data;
      let token = JSON.parse(localStorage.getItem("user_info")).data[
        "mmc-identity"
      ];
      this.ws.onopen = () => {
        this.ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid
          })
        );
      };

      this.ws.onmessage = e => {
        if(e.data != "CONNECT_KEEP" ){
          let metadata = JSON.parse(e.data);
          if (metadata.msgnum == 4700 && metadata.type == 500) {
            // console.log(this.device,"decive");
            if (this.device.customName) {
              if (
                this.device.deviceList[0].deviceHardId ==
                metadata.data.deviceHardId
              ) {
                this.wsData = metadata.data;
                if (this.wsData.anotherName) {
                  this.flag = true;
                }
              }
            } else {
              if (this.device.deviceHardId == metadata.data.deviceHardId) {
                this.wsData = metadata.data;
                  if (
                    this.wsData.anotherName &&
                    this.wsData.anotherName.indexOf("-") > -1
                  ) {
                    let arr = this.wsData.anotherName.split("-");
                    this.wsData.ername = arr[0];
                    this.wsData.sanname = arr[1];
                  } else {
                    this.wsData.ername = this.wsData.anotherName;
                  }
                  // if(this.wsData.ername.includes("县")){
                  //   this.wsData.ername = this.wsData.ername.substr(0,3)
                  // }else{
                  //   this.wsData.ername = this.wsData.ername.substr(0,2) + "区"
                  // }
                if (this.wsData.anotherName) {
                  this.flag = true;
                }
              }
            }
          }
        }
      };
    },

    screenShot() {
      this.getFontSize()
      // 新版sdk使用
      var Dom = document.querySelector("#qingliu_" + this.name);
      var canvas = Dom.getElementsByTagName("canvas")[0];
      //旧版skd使用
      // var canvas = document.querySelector("#sdk_canvas_" + this.name);
      const image = canvasToDataURL(canvas);
      let blob = dataURLToBlob(image);
      return blob;
    },
    fullScreen(flag) {
      if (flag) {
        let dom = document.querySelector("#qingliu_"+this.name);
        dom.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    //字体大小改变
    getFontSize() {
      // 获取需要改变字体大小的元素
      let timeStr = this.$refs.timeStr;
      let qingliu = this.$refs.qingcanvas;
      let wsDataleft = this.$refs.wsDataleft;
      let wsDataright = this.$refs.wsDataright;

      // 根据窗口宽度设置字体大小
      var fontSize = Math.floor(qingliu.clientWidth / 40); // 根据需要进行调整

      // 应用新的字体大小
      timeStr.style.fontSize = fontSize + "px";
      wsDataleft.style.fontSize = fontSize + "px";
      wsDataright.style.fontSize = fontSize + "px";

      let resizeDom = new ResizeObserver(e => {
        // console.log(e,"eeeeeeee2");
        var fontSize = Math.floor(qingliu.clientWidth / 40); // 根据需要进行调整

        // 应用新的字体大小
        timeStr.style.fontSize = fontSize + "px";
        wsDataleft.style.fontSize = fontSize + "px";
        wsDataright.style.fontSize = fontSize + "px";
      });
      resizeDom.observe(qingliu);
    }
  }
};
</script>

<style scoped lang="scss">
.qingliumap{
  top: -400px;
}
.qingliu {
  width: 100%;
  height: calc(100% - 46px);
  .qingcanvas {
    width: 100%;
    height: 100%;
  }
  .ht100 {
    position: absolute;
    top: 0;
    > canvas {
      // background: chartreuse;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99; //开启画布即可画线
    }
    .cross {
      z-index: 90; //开启画布即可画线
    }
  }
  .timeStr {
    position: absolute;
    top: 25px;
    right: 10px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    z-index: 99;
    color: #ffffff;
  }
  .wsDataleft {
    position: absolute;
    bottom: 40px;
    left: 0;
    z-index: 99;
    color: #fff;
  }
  .wsDataright {
    position: absolute;
    bottom: 43px;
    right: 0;
    z-index: 99;
    color: #fff;
    // text-align: right;
  }
  .timeStrStyle {
    top: 60px;
    font-size: 38px;
  }

  .leftStyle {
    bottom: 10px;
    font-size: 38px;
    left: 10px;
  }
  .rightStyle {
    bottom: 10px;
    font-size: 38px;
    right: 10px;
    // .name {
    //   padding: 20px 0;
    // }
  }
  .player_area {
    width: 100%;
    height: 100%;
  }
  .cf {
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    color: #fff;
    background: rgba($color: #000000, $alpha: 0.6);
  }

  // width: 100%;
  // height: 100%;
  /deep/canvas {
    width: 100% !important;
  }
}
.fkLivePlayer {
  .wsDataleft {
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 99;
    color: #fff;
  }
  .wsDataright {
    position: absolute;
    bottom: 10px;
    right: 0;
    // z-index: 99;
    color: #fff;
    text-align: right;
  }
  .leftStyle {
    bottom: 10px;
    font-size: 38px;
    left: 10px;
  }
  .rightStyle {
    font-size: 38px;
    bottom: 10px;
    right: 10px;
    // .name {
    //   padding: 20px 0;
    //   font-size: 38px;
    // }
  }
}
.ycQingLiuPlayer {
  width: 100%;
  height: 195px;
  .timeStr {
    top: 35px;
    font-size: 8px;
  }
  .wsDataleft {
    font-size: 8px;
    bottom: 0px;
  }
  .wsDataright {
    font-size: 8px;
    bottom: 0px;
  }
  .timeStrStyle {
    top: 60px;
    font-size: 38px;
  }
  .leftStyle {
    bottom: 10px;
    font-size: 38px;
    left: 10px;
  }
  .rightStyle {
    font-size: 38px;
    bottom: 10px;
    right: 10px;
    // .name {
    //   padding: 20px 0;
    //   font-size: 38px;
    // }
  }
}
.ycqingliumap{
  height: 92vh;
}
.full-screen-video {
  .timeStr {
    top: 35px;
    font-size: 20px;
  }
  .wsDataleft {
    font-size: 20px;
    bottom: 300px;
  }
  .wsDataright {
    font-size: 20px;
    bottom: 300px;
  }
}
.qingliufull {
  &::v-deep {
    canvas {
      height: 100% !important;
    }
  }
}
</style>
