<template>
  <svg class="symbol-svg-icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
import "@/assets/fonts/ali/iconfont.js";
export default {
  name: "symbol-icon",
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.icon}`;
    },
  },
};
</script>

<style>
.symbol-svg-icon {
  width: 28px;
  height: 28px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>