<template>
  <div class="cpt-nest-dialog">
    <div class="dialog-ctx" :style="containerStyle" :class="{ collapse,changeHeightStyle }">
      <div v-if="showTitle" class="dialog-title">
        {{ title }}
        <div class="nav__left">
          <slot name="nav__left"></slot>
        </div>
        <div class="nav__right " @click='handleClose'>
          <slot name="nav__right"></slot>
        </div>
      </div>
      <div class="dialog-bd">
        <slot></slot>
      </div>
      <img v-if="isCollapse" @click="collapse = !collapse" src="~@/assets/images/observe/fckernel/collapse.png"
        class="icon-collapse" />
      <div class="wih100 h24 pa bottom0  nest-dialog_bottom cp pt5 tc"  @click='changeHeight'>
        <div class="w24 h24  dib  " :class="changeHeightStyle ? 'xbStyle':'' "> <img src="~@/assets/images/observe/fckernel/xb.png" alt=""> </div>
      </div>
    </div>


  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: () => "",
      },
      showTitle: {
        type: Boolean,
        default: () => false,
      },
      containerStyle: {
        type: Object,
        default: () => ({}),
      },
      isCollapse: {
        type: Boolean,
        default: () => true,
      },
      isCollapse2: {
        type: Boolean,
        default: () => false,
      }
    },
    data() {
      return {
        collapse: false,
        changeHeightStyle: false
      };
    },
    methods: {
      changeHeight() {
        this.changeHeightStyle = !this.changeHeightStyle
      },
      handleClose() {
        this.$emit("close");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cpt-nest-dialog {
    position: absolute;
    width: 100%;
    z-index: 99;

    .nest-dialog_bottom {
      background: rgba(13, 82, 143, 0.60);
    }

    .changeHeightStyle {
      height: 637px !important;
    }
    .xbStyle{
      transform:rotateX(141deg) ;
    }
    .dialog-ctx {
      position: absolute;
      width: 476px;
      height: 648px;
      background: rgba(9, 32, 87, 0.70);
      border: 1px solid #70DAF9;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      top: 90px;
      left: 116px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      transform: translateY(0);
      transition: 0.3s;

      &.collapse {
        transform: translateX(-100%);
      }

      .dialog-title {
        width: 100%;
        height: 32px;
        line-height: 32px;
        background-image: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.22) 40%, #05091A 100%);
        border: 1px solid #70DAF9;
        box-shadow: inset 0 0 10px 2px #3F9DFF;
        flex-shrink: 0;
        margin: 0 auto;
        background-size: 100% 100%;
        color: #fff;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 16px;
        color: #92d9ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 700;
        position: relative;

        .nav__left {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
        }

        .nav__right {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }

      .dialog-bd {
        flex: 1;
        box-sizing: border-box;
        overflow: auto;
      }

      .icon-close {
        position: absolute;
        top: 15px;
        right: 13px;
        font-size: 18px;
        color: #2edfff;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .icon-collapse {
        position: absolute;
        right: -24px;
        top: 50%;
        transform: translateY(-50%);
        height: 80px;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
</style>