<template>
  <div class="uavSearch">
    <el-input
      :placeholder="placeholder"
      :suffix-icon="icon"
      v-model="input"
      @input="searchFn"
      clearable
    >
    </el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "请输入内容",
    },
    icon: {
      type: String,
      default: "el-icon-search",
    },
  },
  methods: {
    searchFn() {
      this.$emit("searchFn", this.input);
    },
  },
};
</script>

<style lang="scss" >
.uavSearch {
      padding: 0 10px;
  .el-input--suffix {
    color: #6e9fbf;
    .el-input__inner {
      border: 1px solid #00b6ff;
      box-shadow: 0 2px 4px 0 rgba(28, 94, 124, 0.5), inset 0 0 3px 0 #00b6ff;
      background: transparent;
    color: #6e9fbf;
      border-radius: 6px;
      height: 40px;
    }
    .el-icon-search:before {
      color: #00f8f9;
    }
  }
}
</style>