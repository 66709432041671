import API from "@/api"

export default {

    /**
     * 航线下拉
     * @param {*} params 
     * @returns 
     */
    drd(params) {
        return API.FCKERNEL.DrdAirway(params)
    },
    async add(options) {
        if (!options.destination) {
            this.$message.warning('请选择目标位置')
            return
        }
        let num = parseInt(JSON.parse(options.flightCourseJson).line.baseSpeed)
        let _this = this
        let situationDetail = this.situation_detail
        let optionsObj = Object.assign({}, options)
        let newData = JSON.parse(optionsObj.flightCourseJson)
        newData.line.baseSpeed = num;
        optionsObj.flightCourseJson = JSON.stringify(newData)
        let {
            status,
            message,
            data
        } = await API.AIRWAY.Add(optionsObj);
        // // situationDetail.flight_line_id = data
        // this.$store.commit("situation/SITUATION_DETAIL", situationDetail)
        if (status == 1) {
            console.log(data,'创建任务');
            this.$el_message("创建成功", () => {
                this.isAirwayEdit = false
                // 运行监测关闭
                this.powerFlag =true
                this.ControlFlag=true
            if(this.$refs.TaskListRef){
                this.$refs.TaskListRef.list_airway()
            }
            });
        } else if (status == 102) {
            this.$confirm('是否申请跨电子围栏空域飞行?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let {
                    status
                } = await API.AIRWAY.ApplyFlightLineAuth({
                    id: data.flight_line_span_id,
                    status: 1
                });
                if (status == 0) {
                    this.$message({
                        type: 'success',
                        message: '申请成功!'
                    });
                }
               
            }).catch(async () => {
                let {
                    status
                } = await API.AIRWAY.ApplyFlightLineAuth({
                    id: data.flight_line_span_id,
                    status: 0
                });
                this.airway.add.visible = false
                this.airway_list()
                if (status == 1) {
                    // this.$message('取消申请,航线未规划');
                }
                // if (this.is_from_situation_edit) {
                //     this.airway.add.visible = false
                //     this.change_flightTask_view()
                // }
            });
        } else if (status == 101) {
            this.$message.error(message);
        }
    },
    /**
     * 航线转换
     * @param {*} params 
     * @returns 
     */
    trans(params) {
        return API.FCKERNEL.TransAirway(params)
    },

    /**
     * 上传航线
     * @param {*} data 
     */
    async upload(id) {
        let data = await API.FCKERNEL.TransAirway({ id })
        console.log(data,'上传航线');
        this.uav_mqtt_fn({
            type: 521,
            data
        })
    },

    upload_message(data) {
        if (data.state === "UPLOAD_FINISH" && (data.totalWaypointCount === data.uploadedWaypointIndex)) {
            this.$el_message("航线上传成功")
        }
    }
}